<!-- ======= Aliados Section ======= -->
<section class="section-agents section-t8" id="colaboradores">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="title-wrap d-flex justify-content-between">
                    <div class="title-box">
                        <h2 class="title-a">NUESTROS ALIADOS</h2>
                    </div>

                </div>
            </div>
        </div>
        <swiper  [config]="config">
            <div class="swiper-wrapper">
                <div class="carousel-item-b swiper-slide ">
                    <div class=" card-box-d ">
                        <div class="card-img-d" >
                        <!--<image src="" style="filter:grayscale(100%);" />-->
                        <a href="http://www.elcarmendeviboral-antioquia.gov.co/"><img  src="https://res.cloudinary.com/dcwvuzkpb/image/upload/v1649109748/aliados/MUNICIPIO_EL_CARMEN_DE_VIBORAL_ptihdq.png " alt="" class="img-d img-fluid " style="display: block; filter:grayscale(100%); margin: auto; "></a>
                    </div></div>
                </div>
                <div class="carousel-item-b swiper-slide ">
                    <div class=" card-box-d ">
                        <div class="card-img-d ">
                        <!--<image src="" style="filter:grayscale(100%);" />-->
                        <a href="https://www.sena.edu.co/"><img src="https://res.cloudinary.com/dcwvuzkpb/image/upload/v1649109748/aliados/SENA_q1rkop.jpg " alt=" " class="img-d img-fluid " style="display: block; filter:grayscale(100%); margin: auto;"></a>
                    </div></div>
                </div>
                <div class="carousel-item-b swiper-slide ">
                    <div class=" card-box-d ">
                        <div class="card-img-d ">
                        <!--<image src="" style="filter:grayscale(100%);" />-->
                        <a href="https://www.suraenlinea.com/"><img src="https://res.cloudinary.com/dcwvuzkpb/image/upload/v1649109748/aliados/sura_fpdlxo.jpg " alt=" " class="img-d img-fluid " style="display: block; filter:grayscale(100%); margin: auto;"></a>
                    </div></div>
                </div>
                <div class="carousel-item-b swiper-slide ">
                    <div class=" card-box-d ">
                        <div class="card-img-d ">
                        <!--<image src="" style="filter:grayscale(100%);" />-->
                        <a href="https://manod.com.co/#/"><img src="https://res.cloudinary.com/dcwvuzkpb/image/upload/v1649109747/aliados/manod_znivkz.jpg" alt=" " class="img-d img-fluid " style="display: block; filter:grayscale(100%); margin: auto;"></a>
                    </div></div>
                </div>
                <div class="carousel-item-b swiper-slide ">
                    <div class=" card-box-d ">
                        <div class="card-img-d ">
                        <!--<image src="" style="filter:grayscale(100%);" />-->
                        <a href="https://www.fondoemprender.com/"><img src="https://res.cloudinary.com/dcwvuzkpb/image/upload/v1649109747/aliados/FONDO_EMPREDER_h7hczf.jpg" alt=" " class="img-d img-fluid " style="display: block; filter:grayscale(100%); margin: auto;"></a>
                    </div></div>
                </div>
            </div>
        </swiper>
    </div>
</section>
<!-- FIN SECCION ALIADOS -->